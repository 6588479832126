import React, { useEffect } from 'react';
import "./Home.scss";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { FaLinkedinIn } from "react-icons/fa6";
import Banner from "../../Assests/Img/banner.webp";
import barries1 from "../../Assests/Img/barriers1.webp";
import barries2 from "../../Assests/Img/barriers2.webp";
import kunal from "../../Assests/Img/kunal.webp";
import { SlCalender } from "react-icons/sl";
import investImg from "../../Assests/Img/investImgs.webp";
import investImgMission from "../../Assests/Img/investImg.webp";
import barriersVision from "../../Assests/Img/barriersVision.webp";
import LynNguyen from "../../Assests/Img/LynNguyen.webp";
import event1 from "../../Assests/Img/event1.webp";
import event2 from "../../Assests/Img/event2.webp";
import event3 from "../../Assests/Img/event3.webp";
import Nipun from "../../Assests/Img/Nipun.webp";
import Gagan from "../../Assests/Img/gagan.webp";
import NicholasBarsoum from "../../Assests/Img/NicholasBarsoum.webp";
import BrianGan from "../../Assests/Img/BrianGan.webp";
import RahulSabharwal from "../../Assests/Img/RahulSabharwal.webp";
import icon1 from "../../Assests/Img/icon1.webp";
import icon2 from "../../Assests/Img/icon2.webp";
import icon3 from "../../Assests/Img/icon3.webp";
import icon4 from "../../Assests/Img/icon4.webp";
const Home = () => {
    // eventsData
    const eventsData = [
        {
            imgSrc: event1,
            imgAlt: 'event1',
            imgTitle: 'event1',
            heading: 'Be There, Be Inspired',
            description: 'Join us for an in-depth masterclass where our expert, Kunal, will share actionable strategies and insights on how',
            date: '23 August 2024'
        },
        {
            imgSrc: event2,
            imgAlt: 'event2',
            imgTitle: 'event2',
            heading: 'Next Rentvesting',
            description: 'Tune in to our live webinar with Kunal as he explores the latest trends in the rentvesting market.',
            date: '30 August 2024'
        },
        {
            imgSrc: event3,
            imgAlt: 'event3',
            imgTitle: 'event3',
            heading: 'Meet the Minds',
            description: 'Expand your network and connect with other rentvestors at our exclusive networking event.',
            date: '06 September 2024'
        }
    ];

    // data
    const data = [
        { value: '5', label: 'Chapters' },
        { value: '7+', label: 'Countries' },
        { value: '200+', label: 'Chapter Team Members' },
        { value: '1,000+', label: 'Community Members Worldwide' }
    ];

    // infoData
    const infoData = [
        {
            icon: icon1,
            title: 'Diversification',
            description: 'Diversify your strategy by spreading your investments across different property markets and types. This approach minimizes risk and maximizes your potential returns.'
        },
        {
            icon: icon2,
            title: 'Experience',
            description: 'Benefit from the collective experience of our community. Learn from seasoned rentvestors who have successfully navigated the property market and built substantial portfolios.'
        },
        {
            icon: icon3,
            title: 'Cashflow',
            description: 'Managing your monthly cashflow is key to a successful rentvesting strategy. It’s the net income you generate from your investments after expenses.'
        },
        {
            icon: icon4,
            title: 'Capital Appreciation',
            description: 'Capital appreciation is the increase in the value of your property investment over time—a crucial aspect of building long-term wealth through rentvesting.'
        }
    ];

    // testimonials
    const testimonials = [
        {
            image: Nipun,
            name: 'Nipun Sharma',
            linkedin: 'https://www.linkedin.com/in/nipunsharma?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
            text: 'I’m Nipun, and rentvesting changed my life. A few years ago, I decided to rent an apartment in Sydney’s CBD to enjoy city living while investing in an affordable property in Brisbane. My first purchase was a modest two-bedroom unit in a growing suburb. Over time, the property’s value increased by 40%, and the rental income helped me buy a second investment property. Today, I own three properties, and I’m on track to achieve my long-term financial goals.'
        },
        {
            image: Gagan,
            name: 'Gagan Takker',
            linkedin: 'https://www.linkedin.com/in/gagantakker?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
            text: 'I started my rentvesting journey a few years ago with a modest salary and a big dream. I bought a small apartment in Parramatta while renting a place closer to work in the heart of Sydney. Parramatta’s growth was impressive; the area underwent significant development, and my property’s value soared. I used the equity I built up to invest in another property in Western Sydney. Today, I’m managing a growing portfolio and enjoying financial stability.'
        },
        {
            image: RahulSabharwal,
            name: 'Rahul Sabharwa',
            linkedin: 'https://www.linkedin.com/in/rahul-sabharwal-073ab319/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
            text: 'As a young professional in Sydney, Rahul Sabharwal knew he had to think strategically. He purchased a unit in the up-and-coming suburb of Marrickville while renting in the CBD. Marrickville was rapidly gentrifying, and his property’s value increased significantly. After a few years, he sold the unit for a profit and reinvested in a couple of properties in emerging areas like Chippendale and Redfern. His portfolio has grown, and so has his financial security.'
        },
        {
            image: NicholasBarsoum,
            name: 'Nicholas Barsoum',
            linkedin: '',
            text: 'Nicholas Barsoum took a calculated risk with rentvesting by buying a fixer-upper in Sutherland Shire while renting in the bustling Sydney CBD. He focused on renovating the property to boost its value. After successfully upgrading it, he refinanced and used the extra funds to buy another property in Liverpool. This strategy allowed Nicholas to diversify his investments and build substantial equity. His real estate journey has been incredibly rewarding, both financially and personally.'
        },
        {
            image: BrianGan,
            name: 'Brian Gan',
            linkedin: 'https://www.linkedin.com/in/brian-gan-413a98a9/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
            text: 'Brian Gan took a calculated risk with rentvesting by buying a fixer-upper in Sutherland Shire while renting in the bustling Sydney CBD. He focused on renovating the property to boost its value. After successfully upgrading it, he refinanced and used the extra funds to buy another property in Liverpool. This strategy allowed Brian to diversify his investments and build substantial equity. His real estate journey has been incredibly rewarding, both financially and personally.'
        },
        {
            image: LynNguyen,
            name: 'Lyn Nguyen',
            linkedin: 'https://www.linkedin.com/in/lyn-nguyen-19176b103/?originalSubdomain=au',
            text: 'Lyn Nguyen took a calculated risk with rentvesting by buying a fixer-upper in Sutherland Shire while renting in the bustling Sydney CBD. She focused on renovating the property to boost its value. After successfully upgrading it, she refinanced and used the extra funds to buy another property in Liverpool. This strategy allowed Lyn to diversify her investments and build substantial equity. Her real estate journey has been incredibly rewarding, both financially and personally.'
        }
    ];

    // faqs
    const faqs = [
        {
            id: 'collapseOne',
            headerId: 'headingOne',
            question: '1. What is rentvesting, and how can it benefit me?',
            answer: 'Rentvesting is a strategy where you rent where you want to live and invest in property elsewhere. This approach allows you to enjoy your preferred lifestyle while building wealth through real estate. At Rentvesting Club, we offer resources and support to help you maximize this strategy for financial growth.',
        },
        {
            id: 'collapseTwo',
            headerId: 'headingTwo',
            question: '2. How does the Rentvesting Club support new investors?',
            answer: 'The Rentvesting Club provides expert guidance, real-world success stories, and a supportive community. We offer workshops, online resources, and personalized advice to help you navigate the property market and make informed investment decisions.',
        },
        {
            id: 'collapseThree',
            headerId: 'headingThree',
            question: '3. What kind of properties should I look for as a rentvestor?',
            answer: 'As a rentvestor, you\'ll want to focus on properties with high rental yields and strong capital growth potential. Our club provides valuable insights and tools to identify these opportunities and build a portfolio that aligns with your financial goals.',
        },
        {
            id: 'collapseFour',
            headerId: 'headingFour',
            question: '4. How can I get started with rentvesting?',
            answer: 'Getting started with rentvesting involves understanding the market, setting clear goals, and finding the right properties. Join the Rentvesting Club to access a wealth of information, connect with experienced investors, and receive expert advice tailored to your needs.',
        },
        {
            id: 'collapseFive',
            headerId: 'headingFive',
            question: '5. Can rentvesting help me achieve financial independence?',
            answer: 'Yes, rentvesting can be a powerful tool for building wealth and achieving financial independence. By investing strategically and leveraging rental income, you can create a steady income stream and increase your property portfolio. Our club offers the resources and community support to guide you along this journey.',
        },
        {
            id: 'collapseSix',
            headerId: 'headingSix',
            question: '6. What are the benefits of joining the Rentvesting Club?',
            answer: 'Joining the Rentvesting Club provides you with access to a network of like-minded investors, expert advice, and exclusive resources. We help you stay informed about market trends, investment strategies, and success stories that can inspire and guide your own rentvesting journey.',
        },
        {
            id: 'collapseSeven',
            headerId: 'headingSeven',
            question: '7. How often should I review my rentvesting strategy?',
            answer: 'Regular reviews are crucial to staying on track with your rentvesting goals. At the Rentvesting Club, we encourage periodic assessments to adjust your strategy based on market changes and personal progress. Our resources and community can help you stay updated and make informed decisions.',
        },
    ];

    const options = {
        items: 2,
        loop: true,
        margin: 10,
        nav: true,
        rewind: true,
        // autoplay: true,
        // navText: [
        //     `<span class="custom-prev"><img img src="${leftArrow}" alt="Prev"></span>`,
        //     `<span class="custom-next"><img img src="${rightArrow}" alt="Prev"></span>`
        // ],
        responsive: {
            0: {
                items: 1, // 1 item for screens from 0px and up
            },
            600: {
                items: 1, // 2 items for screens from 600px and up
            },
            1000: {
                items: 2, // 3 items for screens from 1000px and up
            },
        },
    };

    return (
        <>
            <div className='home' id='home'>
                {/* BANNER START */}
                <div className='container' id='banner'>
                    <div className='row'>
                        <div className='col-lg-5 left-side'>
                            <h3 className='short-head'>RENTVESTING CLUB</h3>
                            <h1 className='main-head'>Learn, Invest,<br></br> Thrive: Start Your Rentvesting Journey</h1>
                            <p className='text-desc'>Welcome to Australia's premier Rentvesting Club, where passionate entrepreneurs come together to share insights, strategies, and inspiration. Our mission is to empower you to turn renting into a powerful investment strategy, guiding you every step of the way towards financial freedom and success.</p>
                            <a href='#events'>
                                <button className='common-btn'>SEE OUR EVENTS </button>
                            </a>
                        </div>
                        <div className='col-lg-7 right-side'>
                            <img src={Banner} alt='banner' title='banner'></img>
                        </div>
                    </div>
                </div>
                {/* BANNER END */}
                {/* ----------------- */}
                {/* PORTFOLIOS START */}
                <div className='portfolios bg-img' id='portfolios'>
                    <div className='container'>
                        <div className='row' >
                            <h1 className='main-head'>Powering next-generation Portfolios</h1>
                            <h2>1000+</h2>
                            <p>Members Are Using Rentvesting Club To Start Investing Smarter</p>
                            <p className='Figures'>Figures as of August 2024</p>
                        </div>
                    </div>
                </div>
                {/* PORTFOLIOS END */}
                {/* ----------------- */}
                {/* BARRIERS START */}
                <div className='barriers' id='barriers'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-7'>
                                <img src={barries1} alt='barries1' title='barries1' className='barriers1'></img>
                            </div>
                            <div className='col-lg-3 col-md-5'>
                                <img src={barries2} alt='barries2' title='barries2'></img>
                            </div>
                            <div className='col-lg-5 col-md-12 right-side'>
                                <h1 className='main-head'>Breaking Barriers: Simplifying Your Path to Smart Investing</h1>
                                <p className='text-desc'>At our Rentvesting Club, we break down the barriers that make investing seem overwhelming. We know the investment landscape can be complex and intimidating, with traditional methods often creating unnecessary obstacles. That’s why we simplify the process and bring clarity to investing. Our approach provides actionable insights that empower you to make informed decisions with confidence. With expert guidance tailored to your financial goals, we equip you with the tools and knowledge to navigate the market successfully. Whether you're just starting or looking to diversify, we streamline your path to financial success.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BARRIERS END */}
                {/* --------------------- */}
                {/* INVEST START */}
                <div className='invest' id='invest'>
                    <div className='container'>
                        <div className='row'>
                            <h1 className='main-head'>Invest Without Compromise</h1>
                            <p className='text-desc'>The club's investment portfolio, valued at over $500 million, is crafted to leverage the most robust long-term drivers of the Australian economy. In today’s complex macroeconomic landscape, we believe our Rentvesting Club portfolio is strategically positioned to deliver stable, market-beating returns.</p>
                            <img src={investImg} alt='investImg' title='investImg' className='barriers1'></img>
                        </div>
                    </div>
                </div>
                {/* INVEST END */}
                {/* ---------------------- */}
                {/* COUNT NUMBER START */}
                <div className='alone' id='alone'>
                    <div className='container'>
                        <div className='row'>
                            <h1 className='main-head'>We’re Growing Together</h1>
                            <p className='text-desc'>Our success is built on the strength and passion of our global community. Join us as we grow and make rentvesting a powerful tool for financial freedom.</p>
                        </div>
                        <div className='row'>
                            {data.map((item, index) => (
                                <div key={index} className='col-lg-3 col-md-6'>
                                    <div className='circle'>
                                        <h3>{item.value}</h3>
                                        <p>{item.label}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* ALONE END */}
                {/* --------------------- */}
                <div className='events' id='events'>
                    <div className='container'>
                        <h1 className='main-head'>Upcoming Events</h1>
                        <div className='row events-spacing'>
                            {eventsData.map((event, index) => (
                                <div key={index} className={`col-lg-4 events-box events-box${index + 1}`}>
                                    <div className='event-text'>
                                        <img src={event.imgSrc} alt={event.imgAlt} title={event.imgTitle}></img>
                                        <h2>{event.heading}</h2>
                                        <p className='text-desc'>{event.description}</p>
                                    </div>
                                    <div className='event-btn'>
                                        {/* <button className='btn btn1'><FaMapMarkerAlt /></button> */}
                                        <button className='btn btn2'>
                                            <SlCalender />
                                            <p>{event.date}</p>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* --------------------- */}
                {/* MEET THE HOST START */}
                <div className='meet-the-host container' id='meet-the-host'>
                    <div className='row'>
                        <h1 className='main-head'>Meet the host</h1>
                        <div className='col-lg-4 col-md-5 left-side-main'>
                            <div className='left-side'>
                                <img src={kunal} alt='kunal' title='kunal'></img>
                                <h2>HELLO, I'M<br></br> KUNAL DEV KUMAR</h2>
                                <p>CEO and HOST <br></br> Rentvesting Club</p>
                                <a href='https://www.linkedin.com/in/kunaldevkumar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank'><FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-7 right-side'>
                            <h4>About the host</h4>
                            <p className='text-desc'>Kunal is the CEO and Host of the Rentvesting Club, with years of experience in real estate investment. A seasoned rentvestor, he has successfully built a multi-million-dollar portfolio by strategically renting and investing in high-growth areas across Australia. Passionate about empowering others, Kunal now leads a thriving community of like-minded investors, sharing his expertise and insights to help members achieve financial freedom through smart, informed investment strategies.</p>

                            <button className='common-btn'><a href='#'>CONTACT US</a></button>
                        </div>
                    </div>
                </div>
                {/* MEET THE HOST END */}
                {/* --------------------- */}
                {/* JOIN US START */}
                <div className='join' id='join'>
                    <div className='container'>
                        <h1 className='main-head'>Join Us</h1>
                        <p className='text-desc'>Be part of our monthly rentvesting networking events held across Australia. Connect with like-minded investors, share insights, and grow your property portfolio together.</p>
                        <div className='row for-top-spacing'>
                            {infoData.map((item, index) => (
                                <div key={index} className='col-lg-3 col-md-6'>
                                    <div className={`icon-box icon-box${index + 1}`}>
                                        <img src={item.icon} alt={item.title} title={item.title} />
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                        <hr />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* JOIN US END */}
                {/* --------------------- */}
                {/* TESTIMONIAL START */}
                <div className='container testimonial' id='testimonial'>
                    <h3 className='short-head'>Success Through Smart Rentvesting</h3>
                    <h1 className='main-head'>Inspirational Success Stories</h1>
                    <div className='row'>
                        <div className='carousel'>
                            <OwlCarousel className='owl-theme' {...options}>
                                {testimonials.map((item, index) => (
                                    <div key={index} className='item'>
                                        <div className='item-inner'>
                                            <div className='img-side'>
                                                <img src={item.image} alt={item.name} title={item.name} />
                                                <h3>{item.name}</h3>
                                                <p>follow on <a href={item.linkedin} target='_blank' rel='noopener noreferrer'><FaLinkedinIn /></a></p>
                                            </div>
                                            <div className='text-side'>
                                                <p>{item.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                {/* TESTIMONIAL END */}
                {/* ---------------------- */}
                <div className='mission' id='mission'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 left-side'>
                                <img src={barriersVision} alt='Vision' title='Vision'></img>
                                <h3 className='main-head'>vision</h3>
                                <p className='text-desc'>Rentvesting Club aims to empower our community by turning renting into a wealth-building strategy. We provide inspiration, expert insights, and a supportive community to help you invest in high-growth properties. Our vision is to make rentvesting accessible and effective, guiding you towards financial freedom and property success.</p>
                            </div>
                            <div className='col-md-6 right-side'>
                                <img src={investImgMission} alt='Mission' title='Mission'></img>
                                <h3 className='main-head'>Mission</h3>
                                <p className='text-desc'>Our mission at Rentvesting Club is to educate and inspire everyone on effective rentvesting strategies. We offer practical advice, share real success stories, and foster a community of motivated investors. Our goal is to help you leverage renting as a pathway to building wealth and achieving financial independence.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------------- */}
                <div className='container faq' id='faq'>
                    <h1 className='main-head'>Explore Our FAQ</h1>
                    <div className="accordion" id="accordionExample">
                        {faqs.map((faq, index) => (
                            <div className="accordion-item" key={faq.id}>
                                <h2 className="accordion-header" id={faq.headerId}>
                                    <button
                                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#${faq.id}`}
                                        aria-expanded={index === 0}
                                        aria-controls={faq.id}
                                    >
                                        {faq.question}
                                    </button>
                                </h2>
                                <div
                                    id={faq.id}
                                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                    aria-labelledby={faq.headerId}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <p>{faq.answer}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home

