import React from 'react'
import { Routes, Route } from "react-router-dom";
import Navbar from './Comp/Navbar/Navbar';
import Footer from './Comp/Footer/Footer';
import Home from './Comp/Home/Home';
const AllRoutes = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route index element={<Home />} />
                {/*  <Route path="blogs" element={<Blogs />} />
                <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NoPage />} /> */}
            </Routes>
            <Footer />
        </>
    )
}

export default AllRoutes
