import React from 'react';
import './App.scss';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'jquery/dist/jquery.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

import AllRoutes from './AllRoutes.js';
const App = () => {
  return (
    <>
      <AllRoutes />
    </>
  )
}

export default App

