import React from 'react'
import "./Footer.scss";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
const Footer = () => {
    return (
        <>
            <footer>
                <div className='container' id='footer'>
                    <div className='row'>
                    <div className='col-lg-6 col-md-5 brand desktop-none'>
                            <a class="brand-logo" href="/"><h1><span>rent</span>vesting.</h1></a>
                            <p>Live Where You Want, Invest Where It Counts</p>
                            <div className='social-links'>
                                <a href='' className='first-child'><FaFacebookF /></a>
                                <a href=''><FaInstagram /></a>
                                <a href=''><FaXTwitter /></a>
                                <a href=''><FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 list-style'>
                            <h3>company</h3>
                            <ul>
                                <a href='#barriers'><li>about us</li></a>
                                <a href='#events'><li>events</li></a>
                                <a href='#meet-the-host'> <li>meet the host</li></a>
                            </ul>
                        </div>
                        <div className='col-lg-6 col-md-5 brand mobile-none'>
                            <a class="brand-logo" href="/"><h1><span>rent</span>vesting.</h1></a>
                            <p>Live Where You Want, Invest Where It Counts</p>
                            <div className='social-links'>
                                <a href='' className='first-child'><FaFacebookF /></a>
                                <a href=''><FaInstagram /></a>
                                <a href=''><FaXTwitter /></a>
                                <a href=''><FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 get-in-touch list-style'>
                            <h3>get in touch</h3>
                            <ul>
                            <span className='display-flex'> <a href='tel:1800 725 852'><span><BiSolidPhoneCall /></span><li>1800 725 852</li></a></span>
                                <span className='display-flex'><a href='mailto:hello@rentvesting.com.au' target='_blank'><span><MdEmail /></span> <li>hello@rentvesting.com.au</li></a></span>
                                <span className='display-flex'> <a href='#' target='_blank'><span><FaMapMarkerAlt /></span> <li>Level 35, One International Towers,
                                100 Barangaroo Ave, Sydney NSW 2000</li></a></span>
                            </ul>
                        </div>
                    </div>
                    <div className='row textcenter'>
                        <p>Terms And Conditions |
                        Privacy Policy |</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
