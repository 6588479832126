import React from 'react'
import "./Navbar.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
const Navbar = () => {
    return (
        <>
            <div className='main-navbar' id='navbar'>
                <nav class="navbar navbar-expand-sm">
                    <div class="container">
                        <a class="navbar-brand" href="#">
                            <h1><span>rent</span>vesting.</h1>
                            <h6>Live Where You Want, Invest Where It Counts</h6>
                        </a>

                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#barriers">About us</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#meet-the-host">meet the host</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link" href="#">contact us</a>
                                </li> */}
                                <li class="nav-item">
                                    <a class="nav-link" href="#events">events</a>
                                </li>
                            </ul>
                        </div>
                        <div className='social-links'>
                            <a href='' className='first-child'><FaFacebookF /></a>
                            <a href=''><FaInstagram /></a>
                            <a href=''><FaXTwitter /></a>
                            <a href=''><FaLinkedinIn /></a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar
